<template>
  <div id="IFTTTView" class="container">
    <HeaderComponent :src="serviceIcon" :working="isBusy" />

    <AccessListComponent
      :service-name="serviceName"
      :permissions="accessList"
    />

    <LoginView
      username-field-type="text"
      @submit="authAction"
      @reset="cancelAction"
      :is-busy="isBusy"
    />

    <ErrorMessageComponent :error="error" />

    <LocaleChanger />
  </div>
</template>

<script>
import LoginView from "@/components/LoginView";
import LocaleChanger from "@/components/LocaleChanger";
import HeaderComponent from "@/components/HeaderComponent";
import AccessListComponent from "@/components/AccessListComponent";
import ErrorMessageComponent from "@/components/ErrorMessageComponent";
import permissions from "@/includes/permissions";
import api from "@/includes/api";

export default {
  title: "Google Assistant - AYPRO",
  components: {
    LoginView,
    LocaleChanger,
    HeaderComponent,
    AccessListComponent,
    ErrorMessageComponent,
  },
  data() {
    return {
      isBusy: true,
      authError: null,
      serviceName: "Google Assistant",
      serviceIcon: require("@/assets/service_g-assistant.svg"),
    };
  },
  computed: {
    accessList() {
      return [permissions.SCENES, permissions.DEVICES];
    },
    error() {
      return this.authError && this.$t(`oauth2.${this.authError}`);
    },
  },
  mounted() {
    /*  if (
      !this.$route.query ||
      !this.$route.query.client_id ||
      !this.$route.query.scope ||
      this.$route.query.scope !== "google-actions" ||
      !this.$route.query.state
    )
      window.location.href = "/";
    else  */
    this.isBusy = false;
  },
  methods: {
    authAction(params) {
      const me = this;
      me.authError = null;
      me.isBusy = true;
      api.google
        .auth(params.username, params.password)
        .catch((error) => {
          me.authError = error;
        })
        .finally(() => (me.isBusy = false));
    },
    cancelAction() {
      console.log("cancelAction");
    },
  },
};
</script>

<style>
#app {
  background-repeat: no-repeat;
  background-color: #fefefe;
  background-color: -moz-linear-gradient(
    top,
    #feffff 0%,
    #cad3d3 56%,
    #fafaff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #feffff 0%,
    #cad3d3 56%,
    #fafaff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #feffff 0%, #cad3d3 56%, #fafaff 100%)
    no-repeat; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#fafaff',GradientType=0 ); /* IE6-9 */
}

#IFTTTView {
  width: calc(100vw - 4rem);
  padding: 2rem 2rem 2rem 2rem;
}

#AccessList {
  margin-bottom: 3rem;
}

#AccessList > .item {
  padding: 1rem;
  margin-bottom: 5px;
  background: #efefef;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}
#AccessList > .item > small {
  display: block;
  border-left: 2px solid #ccc;
  padding-left: 0.5rem;
  margin-top: 3px;
  border-radius: 3px;
}

@media only screen and (min-width: 1024px) {
  #app {
    background-image: url("../assets/smart-home.svg");
    background-position: 90% 50%;
    background-size: 640px auto;
    font-size: 110%;
  }

  #IFTTTView {
    background: #fafafa;
    padding: 2rem 2rem 1rem 2rem;
    border: 1px solid #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>